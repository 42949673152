const $ = window.jQuery;

$(document).ready(function($) {
    // fix for the video height on mobile browsers
	$(window).resize(function() {
		if ($(window).innerWidth <= 800) {
			$("iframe").prop("height", "auto");
		}
		else {
			$("iframe").prop("height", "435px");
		}
	}).trigger('resize');

    const scroll_val = sessionStorage.getItem('srp_scroll_val')
    if(scroll_val) {
        setTimeout(() => {
            window.scrollTo(0, parseInt(scroll_val))
            sessionStorage.removeItem('srp_scroll_val')
        }, 500);
    }

    $('.vdp-link').on('click', (e) => {
        sessionStorage.setItem('srp_scroll_val', document.documentElement.scrollTop)
    })
})